import { Flex, Input, notification, Spin, Switch, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "../../redux/organizationSlice";
import CustomCssTransition from "../UI/CustomCssTransition/customCssTransition";
import SaveButton from "../UI/savebutton";

const { Title } = Typography;

export default function MarketingSettings() {
  const [isDirty, setIsDirty] = useState(false);
  const [isThankYouPage, setIsThankYouPage] = useState({
    thankyou_page: false,
    thankyou_page_url: "",
  });

  const { organizationData, loading } = useSelector(
    (state) => state?.organization
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsThankYouPage({
      thankyou_page:
        organizationData?.thankyou_page_config?.thankyou_page ?? false,
      thankyou_page_url:
        organizationData?.thankyou_page_config?.thankyou_page_url ?? "",
    });
  }, [
    organizationData?.thankyou_page_config?.thankyou_page,
    organizationData?.thankyou_page_config?.thankyou_page_url,
  ]);

  const handleSwitchChange = (checked) => {
    setIsThankYouPage({
      ...isThankYouPage,
      thankyou_page: checked,
    });
    setIsDirty(true);
  };

  const handleSubmit = () => {
    if (
      isThankYouPage.thankyou_page &&
      isThankYouPage.thankyou_page_url === ""
    ) {
      notification.error({
        message: "Error",
        description: "Please enter the Thank You page URL",
        duration: 3,
      });
      return;
    }
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: { ...organizationData, thankyou_page_config: isThankYouPage },
      })
    );
    setIsDirty(false);
  };
  return (
    <div>
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={14} style={{ marginBottom: "20px" }}>
        <SettingOutlined style={{ fontSize: 24 }} />
        <Title level={3} style={{ margin: 0 }}>
          Marketing Settings
        </Title>
      </Flex>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <label className="text-base text-black font-semibold">
          Custom Thank You Page
        </label>
        <Tooltip title={null}>
          <Switch
          className="ml-4"
          checked={isThankYouPage?.thankyou_page ?? false}
          onChange={handleSwitchChange}
        />
        </Tooltip>
      </div>
      <CustomCssTransition
        in={isThankYouPage?.thankyou_page}
        timeout={300}
        classNames="glimpse"
        unmountOnExit
      >
        <div className="flex flex-col justify-start gap-4 bg-white shadow-md rounded-lg p-6 mb-6">
        <label className="text-base text-black font-semibold">
            Thank You Page URL
          </label>
          <Input
            placeholder="Enter the Thank You page URL"
            className="profile_input_wrapper"
            style={{ width: "80%" }}
            value={isThankYouPage?.thankyou_page_url}
            onChange={(e) => {
              setIsThankYouPage({
                thankyou_page: isThankYouPage?.thankyou_page,
                thankyou_page_url: e.target.value,
              });
            }}
            allowClear
          />
        </div>
      </CustomCssTransition>

      <SaveButton onSubmit={handleSubmit} isDirty={isDirty} />
    </div>
  );
}
