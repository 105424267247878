import React from "react";

import { Select } from "antd";

const { Option } = Select;

const Step1Panel = ({
  serviceTitanBusinessUnits,
  serviceTitanJobs,
  serviceTitanJobTags,
  tagIds,
  setTagIds,
  selectedBu,
  setSelectedBu,
  selectedJobType,
  setSelectedJobType,
  CampaignList,
  setSelectedCampaign,
  filterOption,
  selectedCampaign,
}) => {
  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <label>Business Unit</label>
        <Select
          showSearch
          filterOption={filterOption}
          placeholder="Select Business Unit"
          value={selectedBu?.id}
          onChange={(value) => {
            const selectedBu = serviceTitanBusinessUnits.find(
              (e) => e.id === value
            );
            setSelectedBu(selectedBu);
          }}
          style={{ width: "100%" }}
        >
          {serviceTitanBusinessUnits.map((bu) => (
            <Option key={bu.id} value={bu.id}>
              {bu.name}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: "16px" }}>
        <label>Job Type</label>
        <Select
          showSearch
          placeholder="Select Job Type"
          value={selectedJobType?.id}
          filterOption={filterOption}
          onChange={(value) => {
            const selectedJob = serviceTitanJobs.find((e) => e.id === value);
            setSelectedJobType(selectedJob);
          }}
          style={{ width: "100%" }}
        >
          {serviceTitanJobs.map((job) => (
            <Option key={job.id} value={job.id}>
              {job.name}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <label>Select Campaign</label>
        <Select
          style={{ width: "100%" }}
          allowClear
          placeholder="Select Campaign"
          value={selectedCampaign?.name}
          onChange={(value) => {
            const campaign = CampaignList.find((e) => e.value === value);
            setSelectedCampaign({
              id: campaign.value,
              name: campaign.label,
            });
          }}
          filterOption={filterOption}
          showSearch
        >
          {CampaignList.map((campaign, index) => (
            <Option key={index} value={campaign.value}>
              {campaign.label}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <label>Select Job Tags</label>
        <Select
          showSearch
          filterOption={filterOption}
          mode="multiple"
          placeholder="Select Job Tags"
          style={{ width: "100%", padding: "5px" }}
          options={
            serviceTitanJobTags?.data
              ? serviceTitanJobTags.data.map((item) => ({
                  label: item.name,
                  value: item.id,
                  desc: item.name,
                }))
              : []
          }
          defaultValue={
            serviceTitanJobTags?.data
              ? serviceTitanJobTags.data
                  .filter((item) => tagIds?.includes(item.id))
                  .map((data) => {
                    return data.id;
                  }) ?? []
              : []
          }
          value={
            serviceTitanJobTags?.data
              ? serviceTitanJobTags.data
                  .filter((item) => tagIds?.includes(item.id))
                  .map((data) => {
                    return data.id;
                  }) ?? []
              : []
          }
          onChange={(value) => {
            setTagIds([...value]);
          }}
          size="large"
        />
      </div>
    </div>
  );
};

export default Step1Panel;
