import React from 'react'
import { Tabs } from 'antd';
import { AnalyticsTabs } from '../../utils/constants'

export default function Analytics() {
  return <Tabs items={AnalyticsTabs.map((item)=>{
		return {
			key: item.name,
			label: (
				<span className='flex items-center gap-3'> 
					{item.icon} {item.name}
				</span>
			),
			children: item.component,
		};
	})}/>
}
