import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import { OrganizationTabs } from "../../utils/constants";
import "./index.css";

const Organization = () => {
  const { organizationData } = useSelector((state) => state?.organization);
  const [activeTabKey, setActiveTabKey] = React.useState(
    OrganizationTabs[0].name
  );

  return (
    <Tabs
      onChange={(key) => setActiveTabKey(key)}
      items={OrganizationTabs.map((item) => {
        if (organizationData?.isSTCapacitySet && item?.isCapacityDependent)
          return null;
        return {
          key: item.name,
          label: (
            <span>
              {React.cloneElement(item.icon, {
                isActive: item.name === activeTabKey,
              })}{" "}
              {item.name}
            </span>
          ),
          children: item.component,
        };
      })}
    />
  );
};

export default Organization;
