import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, theme, Spin } from "antd";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from "react-router-dom";
import { DashboardHeader } from "./components/General/Header";
import SideNavbar from "./components/General/Navbar/SideNav";
import { verifyToken } from "./redux/authSlice";
import { AuthenticatedRoutes, PublicRoutes } from "./utils/constants";
import { fetchServices, fetchTrades } from "./redux/tradeSlice";
import { fetchJobs } from "./redux/jobsSlice";
import { fetchOrganzation, fetchSTJobTags, fetchSTTechnicians } from "./redux/organizationSlice";
import { fetchAllOrganzations } from "./redux/adminSlice";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import NotFound from "./pages/NotFound";

const { Content } = Layout;

const Public = () => {
  const { isAuthenticated,isAdmin } = useSelector((state) => state?.auth);
  if (isAuthenticated) {
    return <Navigate exact to={isAdmin ? "/adminSettings" : "/orgSettings"} replace />;
  }
 
  return <Outlet />;
};
const Protected = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {organizationData} = useSelector((state) => state?.organization);
  const [loadingSidebar, setLoadingSidebar] = useState(true);
  

  const { isAuthenticated, token, authLoading, currentOrganizationId, isAdmin } = useSelector(
    (state) => state?.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(fetchTrades());
    dispatch(fetchServices());
    dispatch(fetchJobs());
    dispatch(fetchSTTechnicians({id: currentOrganizationId}))
  }, [dispatch, currentOrganizationId]);

  useEffect(() => {
    if (isAuthenticated && currentOrganizationId && !authLoading) {
      dispatch(fetchOrganzation(currentOrganizationId));
      dispatch(fetchSTJobTags(currentOrganizationId));
      dispatch(fetchSTTechnicians({id:currentOrganizationId}))
      setLoadingSidebar(false);
    }
  }, [dispatch, authLoading, isAuthenticated, currentOrganizationId]);
  
  useEffect(() => {
    if (isAuthenticated ) {
      dispatch(fetchAllOrganzations());
      if (isAuthenticated) {
        navigate(isAdmin ? "/adminSettings" : "/orgSettings", { replace: true });
      }
    }
  }, [dispatch, isAuthenticated, isAdmin, navigate]);

  useEffect(() => {
    if (token) {
      dispatch(verifyToken({ token }));
    }
  }, [dispatch, token]);

  if (!isAuthenticated && !authLoading && !token) {
    return <Navigate exact to="/" replace />;
  }
  return (
    <>
        <ErrorBoundary fallback={<NotFound/>}>
    {authLoading ? <Spin spinning={authLoading} fullscreen /> : null}
    <Layout style={{ height: "100%" }}>
      <SideNavbar collapsed={collapsed} className="visible" />
      <Layout>
        <DashboardHeader
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          colorBgContainer={colorBgContainer}
        />
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
            <Outlet />
            
        </Content>
      </Layout>
    </Layout>
    
 </ErrorBoundary>
    </>
  );
};


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Public />}>
        {PublicRoutes.map((item) => (
          <Route key={item.name} path={item.routePath} element={item.component} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route path="/" element={<Protected />}>
        {AuthenticatedRoutes.map((item) => (
          <Route key={item.name} path={item.routePath} element={item.component} />
        ))}
      </Route>
    </>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
