import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './customCssTransition.css'

export default function CustomCssTransition({
  children,
  timeout = 300,
  classNames = "default-class",
  ...props
}) {
  return (
    <CSSTransition timeout={timeout} classNames={classNames} {...props}>
      {children}
    </CSSTransition>
  );
}
