import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Flex,
  Input,
  Typography,
  List,
  Row,
  Col,
  Spin,
  Alert,
} from "antd";
import { InsertRowLeftOutlined } from "@ant-design/icons";

import { createTrade } from "../../redux/tradeSlice";

const { Title } = Typography;

const AdminTrades = () => {
  const [tradeName, setTradeName] = useState("");

  const { organizationData } = useSelector((state) => state.organization);
  const { trades, loading } = useSelector((state) => state.trade);

  const dispatch = useDispatch();

  const addTrade = () => {
    if (tradeName) {
      dispatch(
        createTrade({ tradeName, organizationId: organizationData?._id })
      );
    }
  };

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
        <InsertRowLeftOutlined style={{ fontSize: 20 }} />
        <Title level={3} style={{ margin: 0 }}>
          Trades
        </Title>
      </Flex>
      <Row>
        <Col span={6}>
          <Flex vertical={true} gap={20}>
            <Input
              placeholder="Add New Trade"
              value={tradeName}
              onChange={(e) => setTradeName(e.target.value)}
              size="large"
            />
            <Button
              type="primary"
              onClick={addTrade}
              disabled={tradeName.trim() === ""}
              size="large"
              style={{ width: "fit-content" }}
            >
              Submit
            </Button>
          </Flex>
        </Col>
      </Row>

      <br />
      <Alert
        style={{ padding: "0.5rem", marginBottom: "0.5rem" }}
        message={
          <div>
            <span>
              This will just create Trade. For this new Trade to show in your organization's{" "}
              <strong>Trade & Service and Offerings.</strong>
            </span>{" "}
            <span>
              You need to first time activate it from{" "}
              <strong>Activate Trades</strong> in{" "}
              <strong>Organization Navigation Flows</strong>.
            </span>
          </div>
        }
        type="info"
        showIcon
      />

      <Flex style={{ margin: "25px 0px" }}>
        <Title level={3}>Offered Trades</Title>
      </Flex>

      <List
        size="large"
        bordered
        dataSource={trades}
        renderItem={(item) => (
          <List.Item key={item?._id}>
            <List.Item.Meta title={item?.tradeName} />
          </List.Item>
        )}
      />
    </>
  );
};

export default AdminTrades;
