import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Spin,
  Typography,
  Upload,
} from "antd";

import {
  SettingOutlined,
  EditOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  updateOrganization,
  updateServiceTypeOfferings,
  updateTradeOfferings,
} from "../../redux/organizationSlice";
import { TradeIcons } from "../../utils/tradeIcons";
import { getBase64, icons } from "../../utils/constants";
import SaveButton from "../UI/savebutton";

const { Title, Text } = Typography;

const TradeServiceOfferings = () => {
  const htmlRef = useRef(document.querySelector("html"));
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [tradeLogos, setTradeLogos] = useState([]);
  const [servicesLogos, setServicesLogos] = useState([]);
  const [Dirty, setDirty] = useState(false);

  const { organizationData, loading } = useSelector(
    (state) => state?.organization
  );
  const { trades, services } = useSelector((state) => state.trade);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (organizationData?.trades_logo) {
      setTradeLogos(organizationData?.trades_logo || []);
    }
    if (organizationData?.services_logo) {
      setServicesLogos(organizationData?.services_logo || []);
    }
    htmlRef?.current?.style.setProperty(
      "--primary-color",
     organizationData?.primaryColor
    );
    htmlRef?.current?.style.setProperty(
      "--primary-color",
     organizationData?.primaryColor
    );
    
  }, [organizationData]);

  const onChangeTrade = (trade, value) => {
    dispatch(updateTradeOfferings({ trade, value }));
    setDirty(true);
  };

  const onChangeServiceType = (trade, serviceType, value) => {
    dispatch(updateServiceTypeOfferings({ trade, serviceType, value }));
    setDirty(true);
  };

  const handleSubmit = () => {
    const payload = {
      navigation_mapping: organizationData?.navigation_mapping,
      trade_logos: tradeLogos,
      service_logos: servicesLogos,
    };
    dispatch(updateOrganization({ id: organizationData._id, payload }));
    setDirty(false);
  };

  const handleTradeLogosChange = async ({ fileList }, tradeData, tradeName) => {
    const updatedLogos = tradeLogos.filter(
      (item) => item.tradeId !== tradeData?._id
    );

    if (fileList.length > 0) {
      const newFiles = await Promise.all(
        fileList.map(async (file) => {
          const preview = await getBase64(file.originFileObj);
          file["preview"] = preview;
          return {
            tradeId: tradeData?._id,
            tradeName,
            name: tradeData?.tradeName,
            ...file,
          };
        })
      );
      updatedLogos.push(...newFiles);
    }
    setDirty(true);
    setTradeLogos(updatedLogos);
  };
  const handleServiceLogosChange = async (
    { fileList },
    serviceData,
    serviceName
  ) => {
    const updatedLogos = servicesLogos.filter(
      (item) => item.serviceId !== serviceData?._id
    );
    let filteredList = [];
    const existingLogo = servicesLogos.find(
      (item) => item.serviceId === serviceData?._id
    );
    if (existingLogo) {
      filteredList = fileList.filter((item) => item.uid !== existingLogo.uid);
    } else {
      filteredList = fileList;
    }
    const newFiles = await Promise.all(
      filteredList.map(async (file) => {
        const preview = await getBase64(file.originFileObj);
        file["preview"] = preview;
        return {
          serviceId: serviceData?._id,
          serviceName,
          name: serviceData?.name,
          ...file,
        };
      })
    );
    updatedLogos.push(...newFiles);
    setDirty(true);
    setServicesLogos(updatedLogos);
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);
  return (
    <>
      <Spin spinning={loading} tip="Loading..." fullscreen />
      <div
        style={{ display: "flex", gap: 8, alignItems: "center" }}
        className="settings-header"
      >
        <img
          src={require("../../assets/Images/wrench.jpg")}
          height={22}
          width={22}
          alt="map-logo"
          style={{
            verticalAlign: "middle",
            display: "inline-block",
          }}
        />
        <Title level={3} style={{ margin: 0 }}>
          Trades and Service Offerings
        </Title>
      </div>
      <br />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "20px",
          borderRadius: "8px",
        }}
        autoComplete="new-state"
      >
        <Row gutter={[24, 24]}>
          {organizationData?.navigation_mapping?.map((trade) => {
            const tradeData = trades.find(
              (item) => item.tradeName === trade?.trade?.name
            );
            const tradeUrl =
              tradeLogos?.find((Logo) => Logo.tradeId === tradeData?._id)
                ?.url ||
              tradeLogos?.find((Logo) => Logo.tradeId === tradeData?._id)
                ?.preview;
            const TradeIcon = tradeUrl ? (
              <div style={{ position: "relative", width: "60px" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "4px",
                  }}
                  src={tradeUrl}
                  alt={trade?.trade?.name}
                  className="trade-icon"
                />
                <EditOutlined
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    color: "#fff",
                    fontSize: "18px",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : TradeIcons[trade?.trade?.name] ? (
              TradeIcons[trade?.trade?.name]?.icon()
            ) : (
              <UploadOutlined />
            );

            return (
              <React.Fragment key={trade?.trade?.id}>
                <Col span={2}>
                  <Upload
                    action="https://file.io"
                    listType="picture-card"
                    fileList={tradeLogos?.filter(
                      (item) => item?.tradeId === tradeData?._id
                    )}
                    beforeUpload={() => false}
                    onPreview={handlePreview}
                    onChange={(e) =>
                      handleTradeLogosChange(e, tradeData, trade?.trade?.name)
                    }
                    maxCount={1}
                    style={{ textAlign: "center" }}
                    showUploadList={false}
                  >
                    {TradeIcon}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="organization_logo"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>
                <Col span={10}>
                  <div className="trade-offering">
                    <Checkbox
                      checked={trade?.isActive}
                      onChange={(e) =>
                        onChangeTrade(trade?.trade?.name, e.target.checked)
                      }
                      style={{ fontSize: "16px" }}
                    >
                      <Text strong style={{ fontSize: "18px" }}>
                        {trade?.trade?.name}
                      </Text>
                    </Checkbox>
                  </div>
                  <div
                    className="service-type-checkboxes"
                    style={{ marginTop: "10px" }}
                  >
                    {[
                      // Map over the offeredServiceTypes if it exists
                      ...Object.keys(trade?.offeredServiceTypes || {}).map(
                        (serviceType) =>
                          serviceType !== "additionalServices" && (
                            <Checkbox
                              key={serviceType}
                              checked={trade?.offeredServiceTypes[serviceType]}
                              onChange={(e) =>
                                onChangeServiceType(
                                  trade?.trade?.name,
                                  serviceType,
                                  e.target.checked
                                )
                              }
                              disabled={!trade?.isActive}
                              style={{ marginRight: "8px" }}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {serviceType.charAt(0).toUpperCase() +
                                  serviceType.slice(1)}
                              </Text>
                            </Checkbox>
                          )
                      ),

                      // Check if additionalServices exists before mapping over it
                      ...(trade?.offeredServiceTypes?.additionalServices
                        ? Object.keys(
                            trade?.offeredServiceTypes?.additionalServices
                          ).map((serviceType) => (
                            <Checkbox
                              key={serviceType}
                              checked={
                                trade?.offeredServiceTypes?.additionalServices[
                                  serviceType
                                ]
                              }
                              onChange={(e) =>
                                onChangeServiceType(
                                  trade?.trade?.name,
                                  serviceType,
                                  e.target.checked
                                )
                              }
                              disabled={!trade?.isActive}
                              style={{ marginRight: "8px" }}
                            >
                              <Text
                                style={{
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {serviceType.charAt(0).toUpperCase() +
                                  serviceType.slice(1)}
                              </Text>
                            </Checkbox>
                          ))
                        : []), // If additionalServices is not available, return an empty array
                    ]}
                  </div>
                  <Divider style={{ margin: "12px 0" }} />
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
      </Form>
      <Divider />
      <div
        style={{
          padding: "30px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{ display: "flex", gap: 8, alignItems: "center" }}
          className="settings-header"
        >
          <SettingOutlined style={{ fontSize: "20px" }} />

          <Title level={3} style={{ margin: 0, marginBottom: 16 }}>
            Service Types Logo
          </Title>
        </div>
        <br />
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          style={{
            backgroundColor: "#f9f9f9",
            padding: "24px",
            borderRadius: "12px",
          }}
          autoComplete="new-state"
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "24px",
            }}
          >
            {services.map((service) => {
              const serviceUrl =
                servicesLogos.find((item) => item.serviceId === service?._id)
                  ?.url ||
                servicesLogos.find((item) => item.serviceId === service?._id)
                  ?.preview;

              const serviceIcon = serviceUrl ? (
                <div
                  style={{
                    position: "relative",
                    width: "50%",
                    aspectRatio: "1 / 1",
                  }}
                >
                  <div>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                      }}
                      src={serviceUrl}
                      alt={service?.name}
                      className="trade-icon"
                    />
                    <EditOutlined
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        color: "#fff",
                        fontSize: "18px",
                        padding: "4px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "rgba(0, 0, 0, 0.8)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "rgba(0, 0, 0, 0.6)")
                      }
                    />
                  </div>
                </div>
              ) : icons[service?.id] ? (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="icon_otr">
                  {React.createElement(icons[service?.name?.toLowerCase()])}
                  </div>
                  <EditOutlined
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      borderRadius: "50%",
                      color: "#fff",
                      fontSize: "18px",
                      padding: "4px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        "rgba(0, 0, 0, 0.8)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        "rgba(0, 0, 0, 0.6)")
                    }
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                    padding: "20px",
                    backgroundColor: "#fafafa",
                    border: "1px dashed #d9d9d9",
                    borderRadius: "8px",
                  }}
                >
                  {icons[service?.id] &&
                    React.createElement(icons[service?.id])}
                  <PlusOutlined
                    style={{ fontSize: "24px", color: "#1890ff" }}
                  />
                  <span
                    style={{
                      marginTop: "8px",
                      color: "#1890ff",
                      fontWeight: "500",
                    }}
                  >
                    Upload
                  </span>
                </div>
              );

              return (
                <div key={service?._id} style={{ textAlign: "center" }}>
                  <h3 style={{ fontWeight: "500", marginBottom: "12px" }}>
                    {service?.name}
                  </h3>
                  <Upload
                    action="/upload"
                    listType="picture-card"
                    showUploadList={false}
                    fileList={servicesLogos?.filter(
                      (item) => item?.serviceId === service?._id
                    )}
                    onChange={(e) =>
                      handleServiceLogosChange(e, service, service?.name)
                    }
                    beforeUpload={() => false}
                  >
                    {serviceIcon}
                  </Upload>
                </div>
              );
            })}
          </div>
        </Form>
      </div>
      <SaveButton onSubmit={handleSubmit} isDirty={Dirty} />
    </>
  );
};

export default TradeServiceOfferings;
