import { Card, Select, Space, Spin, Button } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BarsOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";
import { updateOrganization } from "../../../../redux/organizationSlice";
import "./ChangeOrder.css";

const { Option } = Select;

export default function ChangeOrder() {
  const { organizationData, loading } = useSelector(
    (state) => state.organization
  );
  const { services } = useSelector((state) => state.trade);

  const dispatch = useDispatch();

  const [selectedTrade, setSelectedTrade] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [draggingCategory, setDraggingCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [targetIndex, setTargetIndex] = useState(null);
  const draggingRef = useRef(null);

  useEffect(() => {
    setSelectedTrade(null);
    setCategories([]);
    setSelectedServiceType(null);
    setSelectedTrade(null);
  }, [organizationData]);

  useEffect(() => {
    if (selectedTrade && selectedServiceType) {
      const selectedCategories =
        organizationData.navigation_mapping?.find(
          (item) => item.trade.id === selectedTrade
        )?.[selectedServiceType] || organizationData.navigation_mapping?.find(
          (item) => item.trade.id === selectedTrade
        )?.additionalServices?.[selectedServiceType] || [];
      setCategories(selectedCategories);
    } else {
      setCategories([]);
    }
  }, [selectedTrade, selectedServiceType, organizationData]);

  const handleTradeChange = (value) => {
    setSelectedTrade(value);
    setSelectedServiceType(null);
  };

  const handleServiceTypeChange = (value) => {
    setSelectedServiceType(value);
  };

  const handleReset = () => {
    setSelectedTrade(null);
    setSelectedServiceType(null);
    setCategories([]);
  };

  const trades =
    organizationData?.navigation_mapping?.map((item) => ({
      id: item.trade.id,
      name: item.trade.name,
    })) ?? [];

  const onDragStart = (category, index) => {
    setDraggingCategory(category);
    draggingRef.current = index;
  };

  const onDragStop = (e, data) => {
    if (draggingRef.current === null) return;

    const newOrder = [...categories];
    const draggingIndex = draggingRef.current;
    const targetIndex = Math.min(
      Math.max(Math.round((draggingIndex * 70 + data.y) / 70), 0),
      categories.length - 1
    );

    if (draggingIndex !== targetIndex) {
      newOrder.splice(draggingIndex, 1);
      newOrder.splice(targetIndex, 0, draggingCategory);
      setCategories(newOrder);
    }

    setDraggingCategory(null);
    draggingRef.current = null;
    setTargetIndex(null);
  };

  const onDrag = (e, data) => {
    const draggingIndex = draggingRef.current;
    const newTargetIndex = Math.min(
      Math.max(Math.round((draggingIndex * 70 + data.y) / 70), 0),
      categories.length - 1
    );

    if (newTargetIndex > draggingIndex) {
      setTargetIndex(newTargetIndex + 1);
    } else {
      setTargetIndex(newTargetIndex);
    }
  };

  const saveOrder = () => {
    const updatedOrganizationData = JSON.parse(
      JSON.stringify(organizationData)
    );
    const trade = updatedOrganizationData.navigation_mapping.find(
      (item) => item.trade.id === selectedTrade
    );
    if(Object.keys(trade).includes(selectedServiceType)){
      trade[selectedServiceType] = categories;
      dispatch(
        updateOrganization({
          id: organizationData._id,
          payload: updatedOrganizationData,
        })
      );
      handleReset();
    }
    else{
      trade.additionalServices = trade?.additionalServices || {};
      trade.additionalServices[selectedServiceType] = categories;
      dispatch(
        updateOrganization({
          id: organizationData._id,
          payload: updatedOrganizationData,
        })
      );
      handleReset();
    }
  };

  return (
    <>
      {loading && <Spin spinning={loading} fullscreen />}
      <div style={{ padding: "24px", maxWidth: "100%", margin: "0 auto" }}>
        <Card
          title="Reorder Categories"
          bordered={false}
          style={{
            width: "100%",
            marginBottom: 24,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
          headStyle={{ textAlign: "center", color: "#555", fontWeight: "bold" }}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Select
              placeholder="Select Trade"
              onChange={handleTradeChange}
              style={{ width: "100%" }}
              value={selectedTrade}
            >
              {trades.map((trade) => (
                <Option key={trade.id} value={trade.id}>
                  {trade.name}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="Select Service Type"
              onChange={handleServiceTypeChange}
              style={{ width: "100%" }}
              disabled={!selectedTrade}
              value={selectedServiceType}
            >
              {services.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name.charAt(0).toUpperCase() + type.name.slice(1)}
                </Option>
              ))}
            </Select>

            {selectedServiceType && (
              <div
                style={{
                  marginTop: "16px",
                  padding: "8px",
                  background: "#f5f5f5",
                  borderRadius: "4px",
                }}
              >
                {categories.map((category, index) => (
                  <Draggable
                    key={category._id}
                    axis="y"
                    position={{ x: 0, y: 0 }}
                    onStart={() => onDragStart(category, index)}
                    onDrag={onDrag}
                    onStop={onDragStop}
                  >
                    <div
                      className={`draggable-item ${
                        targetIndex === index ? "drag-over" : ""
                      }`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px",
                        marginBottom: "8px",
                        background:
                          draggingCategory?._id === category._id
                            ? "#e6f7ff"
                            : "#fff",
                        border:
                          draggingCategory?._id === category._id
                            ? "2px solid #1890ff"
                            : "1px solid #ddd",
                        borderRadius: "4px",
                        cursor: "move",
                        boxShadow:
                          draggingCategory?._id === category._id
                            ? "0 4px 8px rgba(0,0,0,0.2)"
                            : "0 2px 4px rgba(0,0,0,0.1)",
                        transition: "all 0.2s ease",
                        position: "relative",
                      }}
                    >
                      <div
                        className="dragging-bar"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "16px",
                          transform: "translateY(-50%)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      >
                        <BarsOutlined color="gray" style={{height:"100%",width:"100%"}} />
                      </div>
                      {category.category_name}
                      {targetIndex === index && (
                        <div
                          className="violet-line"
                          style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 0,
                            height: "2px",
                            background: "gray",
                          }}
                        />
                      )}
                    </div>
                  </Draggable>
                ))}
              </div>
            )}

            <Button
              onClick={saveOrder}
              style={{ width: "100%", marginBottom: "2px" }}
            >
              Save
            </Button>
            <Button onClick={handleReset} style={{ width: "100%" }}>
              Reset
            </Button>
          </Space>
        </Card>
      </div>
    </>
  );
}
