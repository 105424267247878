import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Spin, Switch, Tooltip, Typography } from "antd";
import {SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSTBusinessUnits,
  updateOrganization,
} from "../../redux/organizationSlice";

import CustomCssTransition from "../UI/CustomCssTransition/customCssTransition";
const { Title } = Typography;

function CapacitySettings() {
  const { organizationData, loading } = useSelector(
    (state) => state?.organization
  );

  const dispatch = useDispatch();

  const { setValue, watch, reset } = useForm({
    defaultValues: {
      isSTCapacitySet: organizationData?.isSTCapacitySet || false,
      isCapacityUnthrottled: organizationData?.isCapacityUnthrottled || false,
    },
  });

  const isSTCapacitySet = watch("isSTCapacitySet");
  const isCapacityUnthrottled = watch("isCapacityUnthrottled");

  useEffect(() => {
    if (organizationData) {
      reset({
        isSTCapacitySet: organizationData?.isSTCapacitySet || false,
        isCapacityUnthrottled: organizationData?.isCapacityUnthrottled || false,
      });
    }
  }, [organizationData]);

  const handleSwitchChange = (checked) => {
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: { ...organizationData, isSTCapacitySet: checked },
      })
    );
    setValue("isSTCapacitySet", checked, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleUnthrottledSwitchChange = (checked) => {
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: { ...organizationData, isCapacityUnthrottled: checked },
      })
    );
    setValue("isCapacityUnthrottled", checked, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <div className="min-h-screen">
      <div className="flex items-center gap-4 mb-8">
        <SettingOutlined style={{ fontSize: 20 }} />
        <Title level={3} className="text-black mb-0">
          Capacity Settings
        </Title>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="flex items-center gap-2">
          <label className="text-base font-semibold text-black">
            Is ServiceTitan Capacity Configured?
          </label>
          <Tooltip
            title={null}
          >
            <Switch
              className="ml-4"
              checked={isSTCapacitySet}
              onChange={handleSwitchChange}
            />
          </Tooltip>
          <Spin spinning={loading} fullscreen />
        </div>
      </div>

      <CustomCssTransition
        in={!isSTCapacitySet}
        timeout={500}
        classNames="glimpse"
        unmountOnExit
      >
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="flex items-center">
            <label className="text-base font-semibold text-black">
              Do you want to trigger unthrottled capacity?
            </label>

            <Tooltip title={null}>
              <Switch
                className="ml-4"
                checked={isCapacityUnthrottled}
                onChange={handleUnthrottledSwitchChange}
                style={{ width: 35, marginRight: 20 }}
              />
            </Tooltip>
          </div>
        </div>
      </CustomCssTransition>
    </div>
  );
}

export default CapacitySettings;
