// MapLogoIcon.js
import React from 'react';

const WrenchLogoIcon = ({ isActive }) => {
  console.log('isActive', isActive);
  return (
    <img
      src={isActive?require('../../assets/Images/blue-wrench.png'):require('../../assets/Images/wrench.png')}
      alt="map-logo"
      height={16}
      width={16}
      style={{
        backgroundColor: "white",
        verticalAlign: "middle",
        display: "inline-block",
        marginRight: "10px",
        marginBottom: "2px",
      }}
    />
  );
};

export default WrenchLogoIcon;
