import React, { useState, useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { Modal } from 'antd';

export const usePrompt = ({
  isDirty = false,
  title = 'You have unsaved changes!',
  subtitle = 'Are you sure you want to leave?',
  confirmText = 'Leave',
  cancelText = 'Stay',
  onConfirm,
  onCancel,
  type = 'warning',
}) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const blocker = useBlocker(isDirty);

  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);
    return new Promise((resolve) => {
      Modal.confirm({
        title,
        content: subtitle,
        okText: confirmText,
        cancelText: cancelText,
        onOk: () => {
          resolve(true);
          onConfirm?.();
        },
        onCancel: () => {
          resolve(false);
          onCancel?.();
        },
      });
    });
  }, [isDirty, confirmText, cancelText, onConfirm, onCancel, subtitle, title]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsBannerVisible(true);
    } else {
      setIsBannerVisible(false);
    }
  }, [blocker.state]);

  const handleConfirmBanner = useCallback(() => {
    setIsBannerVisible(false);
    blocker.proceed();
    blocker.reset();
  }, [blocker]);

  const handleCancelBanner = useCallback(() => {
    setIsBannerVisible(false);
    blocker.reset();
  }, [blocker]);

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => subtitle;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, subtitle]);

  return {
    confirm,
    isBannerVisible,
    blocker,
    handleConfirmBanner,
    handleCancelBanner,
  };
};

export const BlockerBanner = ({ title, subtitle, onConfirm, onCancel }) => {
  return (
    <Modal
      title={title}
      visible={true}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Leave"
      cancelText="Stay"
      centered
      maskClosable={false}
      width={400}
    >
      <p>{subtitle}</p>
    </Modal>
  );
};
