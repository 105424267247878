import { useState,memo } from "react";
import { Button, Card, Col, Divider, Input, Radio, Row, Spin, Typography } from "antd";
import { PartitionOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createJob } from "../../redux/jobsSlice";
import JobsOfferingListing from "./JobOfferingsListing";

const { Title } = Typography;

const AdminJobOfferings = () => {
	const dispatch = useDispatch();
	const { trades, services, loading } = useSelector((state) => state.trade);

	const [jobItem, setJobItem] = useState("");
	const [tradeId, setTradeId] = useState("");
	const [serviceType, setServiceType] = useState("");

	const addJobType = async () => {
		if (jobItem && tradeId && serviceType) {
			const values = {
				tradeId,
				jobItem,
				serviceType,
			};
			const result = dispatch(createJob({ values }));
			if (result.type === "job/create/fulfilled") {
				setJobItem("");
			}
		}
	};

	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Row justify="start" style={{ marginBottom: "30px" }}>
				<Col>
					<Title level={3} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<PartitionOutlined style={{ fontSize: 24 }} />
						<span>Jobs Items</span>
					</Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col style={{ width: "80%" }}>
					<Card
						bordered
						style={{
							borderRadius: 8,
							boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
							textAlign: "center",
							padding: "20px",
						}}
					>
						<Input
							placeholder="Add New Job Type"
							value={jobItem}
							onChange={(e) => setJobItem(e.target.value)}
							size="large"
							style={{ marginBottom: 16, width: "100%" }}
						/>
						<Divider orientation="center" plain>
							Select Trade
						</Divider>
						<Radio.Group
							options={trades.map((trade) => ({
								label: trade.tradeName,
								value: trade._id,
							}))}
							onChange={(e) => setTradeId(e.target.value)}
							optionType="button"
							buttonStyle="solid"
							style={{
								marginBottom: 16,
								display: "flex",
								justifyContent: "center",
								gap: "10px",
								flexWrap: "wrap",
							}}
						/>
						<Divider orientation="center" plain>
							Select Service Type
						</Divider>
						<Radio.Group
							options={services.map((service) => ({
								label: service.name,
								value: service.id,
							}))}
							onChange={(e) => setServiceType(e.target.value)}
							optionType="button"
							buttonStyle="solid"
							style={{
								marginBottom: 16,
								display: "flex",
								justifyContent: "center",
								gap: "10px",
								flexWrap: "wrap",
							}}
						/>
						<Button
							type="primary"
							onClick={addJobType}
							disabled={!jobItem.trim() || !tradeId || !serviceType}
							size="large"
							style={{
								width: "50%",
								marginTop: 20,
								borderRadius: 5,
								fontWeight: "bold",
							}}
						>
							Submit
						</Button>
					</Card>
				</Col>
			</Row>
			<Row justify="center" style={{ marginTop: "40px" }}>
				<Col style={{ width: "80%" }}>
					<Card bordered style={{ borderRadius: 8, boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}>
						<Title level={3} style={{ textAlign: "center", marginBottom: 20 }}>
							Offered Jobs
						</Title>
						<JobsOfferingListing />
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default AdminJobOfferings;
