import React, { useEffect } from "react";
import { Tabs } from "antd";
import { CapacityTabs } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchSTBusinessUnits } from "../../redux/organizationSlice";

function CapacityPlanning() {
  const { organizationData } = useSelector((state) => state?.organization);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSTBusinessUnits(organizationData?._id));
  }, [dispatch, organizationData?._id]);
  return (
    <Tabs
      items={CapacityTabs.map((item) => {
        if (
          organizationData?.isSTCapacitySet &&
          item.name === "Capacity Management"
        ) {
          return null;
        } else {
          return {
            key: item.name,
            label: (
              <span>
                {item.icon} {item.name}
              </span>
            ),
            children: item.component,
          };
        }
      })}
    />
  );
}

export default CapacityPlanning;
