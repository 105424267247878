import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSTCampaigns } from "../../redux/organizationSlice";
import { Tabs } from "antd";
import { MarketingTabs } from "../../utils/constants";

const Campaigns = () => {
	const dispatch = useDispatch();
	const { currentOrganizationId } = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(fetchSTCampaigns(currentOrganizationId));
	}, [dispatch, currentOrganizationId]);

	
	return <Tabs items={MarketingTabs.map((item)=>{
		return {
			key: item.name,
			label: (
				<span>
					{item.icon} {item.name}
				</span>
			),
			children: item.component,
		};
	})}/>
};

export default Campaigns;