import React from "react";

import { Flex, InputNumber, Select} from "antd";

import DispatchText from "../../../components/Jobs/dispatchText";

const { Option } = Select;

const Step3Panel = ({
  customerType,
  setCustomerType,
  additionalInfo,
  setAdditionalInfo,
  isdispatchFee,
  setIsDispatchFee,
  dispatchDetails,
  handleDispatchTextChange,
}) => {
  return (
    <div>
      <label>Select Customer Type</label>
      <Select
        placeholder="Select Customer Type"
        onChange={(value) => setCustomerType(value)}
        value={customerType}
        style={{ width: "100%", marginBottom: "16px" }}
      >
        <Option value="Existing Customer">Existing Customer</Option>
        <Option value="New Customer">New Customer</Option>
        <Option value="Both">Both</Option>
      </Select>

      <label>Additional Info</label>
      <Select
        placeholder="Additional Info"
        onChange={setAdditionalInfo}
        style={{ width: "100%", marginBottom: "16px" }}
        value={additionalInfo}
      >
        <Option value={true}>Yes</Option>
        <Option value={false}>No</Option>
      </Select>

      <label>Dispatch Fee</label>
      <Select
        placeholder="Dispatch Fee"
        onChange={(value) => setIsDispatchFee(value)}
        style={{ width: "100%", marginBottom: "16px" }}
        value={isdispatchFee}
      >
        <Option value={true}>Yes</Option>
        <Option value={false}>No</Option>
      </Select>
      <br />

      {isdispatchFee && (
        <>
          <Flex gap={10} style={{ width: "100%" }} align="center">
            <Flex vertical gap={5}>
              <label type="secondary" strong underline>
                Member Dispatch Fee
              </label>
              <InputNumber
                size="middle"
                addonBefore="$"
                defaultValue={0}
                min={0}
                value={dispatchDetails?.member_dispatch_fee}
                onChange={(e) =>
                  handleDispatchTextChange({ member_dispatch_fee: e })
                }
                placeholder="Member Dispatch Fee"
                style={{ width: 150 }}
              />
            </Flex>
          </Flex>
          <div style={{ boxSizing: "border-box" }}>
            <DispatchText
              member_dispatch_fee={dispatchDetails?.member_dispatch_fee}
              dispatch_fee={dispatchDetails.dispatch_fee}
              isDefault={false}
              dispatchVisibility={false}
              header={dispatchDetails.dispatch_header}
              body={dispatchDetails.dispatch_body}
              checkboxText={dispatchDetails.dispatch_text}
              onChange={handleDispatchTextChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Step3Panel;
