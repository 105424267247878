import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Row, Spin, Typography } from "antd";
import {SyncOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { initiateInitialExtraction } from "../../../redux/adminSlice";
import { fetchSTBusinessUnits } from "../../../redux/organizationSlice";
const { Title, Text } = Typography;

const ServiceTitanDetails = () => {
  const dispatch = useDispatch();
  const {
    organizationData,
    serviceTitanBusinessUnits,
    serviceTitanJobs,
    loading,
    serviveTitanLoading,
  } = useSelector((state) => state?.organization);
  const { isAdmin, currentOrganizationId } = useSelector(
    (state) => state?.auth
  );

  // Local state for tracking sync button loading
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    dispatch(fetchSTBusinessUnits(currentOrganizationId));
  }, [dispatch, currentOrganizationId]);

  const handleSyncData = async () => {
    setIsSyncing(true);
    await dispatch(initiateInitialExtraction(organizationData?._id));
    setIsSyncing(false);
  };

  return (
    <>
      <Spin spinning={loading || serviveTitanLoading || isSyncing} fullscreen />
      <Card
        style={{
          background: "black",
          borderRadius: "15px",
          marginBottom: "30px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row align="middle" justify="space-between">
          <Col>
            <Title
              level={4}
              style={{
                margin: 0,
                color: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../../assets/Images/service-titan.png")}
                alt="Service Titan Logo"
                style={{ width: "40px", height: "40px" }}
              />
              ServiceTitan Business Units And Job Types
            </Title>
          </Col>
          {isAdmin && (
            <Col>
              <Button
                type="dashed"
                shape="round"
                icon={<SyncOutlined spin={isSyncing} />}
                onClick={handleSyncData}
                loading={isSyncing}
                style={{ background: "rgb(4 42 87)", color: "white" }}
                disabled={loading || serviveTitanLoading || isSyncing}
                className="custom-dashed-button"
              >
                Sync Data
              </Button>
            </Col>
          )}
        </Row>
      </Card>

      <Row gutter={32}>
        <Col span={8}>
          <Card
            title="Business Units"
            bordered={false}
            style={{
              borderRadius: "10px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {serviceTitanBusinessUnits?.length > 0 ? (
              serviceTitanBusinessUnits.map((businessUnit, index) => (
                <div
                  key={index}
                  style={{
                    borderBottom:
                      index !== serviceTitanBusinessUnits.length - 1
                        ? "1px solid #f0f0f0"
                        : "none",
                    padding: "10px 0",
                  }}
                >
                  <Text
                    strong
                    style={{
                      color: "#595959",
                    }}
                  >
                    {businessUnit?.name}
                  </Text>
                </div>
              ))
            ) : (
              <Text type="secondary">No Business Units Available</Text>
            )}
          </Card>
        </Col>

        <Col span={16}>
          <Card
            title="Job Types"
            bordered={false}
            style={{
              borderRadius: "10px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {serviceTitanJobs?.length > 0 ? (
              serviceTitanJobs.map((job, index) => (
                <div
                  key={index}
                  style={{
                    borderBottom:
                      index !== serviceTitanJobs.length - 1
                        ? "1px solid #f0f0f0"
                        : "none",
                    padding: "10px 0",
                  }}
                >
                  <Text
                    strong
                    style={{
                      color: "#595959",
                    }}
                  >
                    {job?.name}
                  </Text>
                </div>
              ))
            ) : (
              <Text type="secondary">No Job Types Available</Text>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ServiceTitanDetails;
