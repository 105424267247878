import {Tabs } from "antd";
import ServiceTitanDetails from "./ServiceTitanBusinessUnits/ServiceTitanDetails";
import JobsMappingUpload from "./ServiceTitanBusinessUnits/UploadJobMappings";
import ThirdPartyInfo from "./ThirdPartyInfo";

const childComponents = [
	{
		label: `ServiceTitan Details`,
		key: 1,
		children: <ServiceTitanDetails />,
	},
	{
		label: `Upload Job Mappings`,
		key: 2,
		children: <JobsMappingUpload />,
	},
	{
		label: "API Integration Keys",
		key: 3,
		children: <ThirdPartyInfo />,
	  },
];

const ServiceTitanBusinessUnits = () => {
	return (
		<Tabs
			defaultActiveKey="1"
			tabPosition={"left"}
			style={{
				height: "auto",
			}}
			items={childComponents.map((item, i) => {
				return {
					label: item.label,
					key: item.key,
					children: item.children,
				};
			})}
		/>
	);
};

export default ServiceTitanBusinessUnits;
