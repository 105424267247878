import { Dropdown, Avatar,Typography, Button, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { handleLogout } from '../../../redux/authSlice';

const { Text } = Typography;

const UserDropdown = ({ user }) => {
  const dispatch = useDispatch();
  const menu = (
    <div
      style={{
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        minWidth: '180px',
      }}
    >
      <div style={{ marginBottom: '12px' }}>
        <Text strong style={{ fontSize: '14px', color: '#4a4a4a' }}>
          {user?.email || "User Email"}
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type="primary"
        icon={<LogoutOutlined />}
        onClick={() => dispatch(handleLogout())}
        style={{
          width: '60%',
          backgroundColor: '#ff4d4f',
          borderColor: '#ff4d4f',
          fontSize: '13px',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          transition: 'background-color 0.3s ease',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#f5222d')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff4d4f')}
      >
        Sign Out
      </Button>
      </div>
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['hover', 'click']} placement="bottomRight">
        <Avatar
          style={{
            background: "rgb(4 42 87)",
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          {user?.email?.[0]?.toUpperCase()}
        </Avatar>
    </Dropdown>
  );
};

export default UserDropdown;
