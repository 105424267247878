import { Table, DatePicker, Spin, Button, Space, Input } from "antd";
import React, { useState, useEffect, useRef } from "react";

import dayjs from "dayjs";
import * as XLSX from "xlsx";

import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

import axiosInstance from "../../redux/apiAxios";
import {
  FileExcelOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { MAX_Leads } from "../../utils/constants";

const { RangePicker } = DatePicker;

const DetailedAnalysis = () => {
  const { organizationData} = useSelector(
    (state) => state.organization
  );
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [defaultRange, setDefaultRange] = useState([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 25,
      total: 0,
      showSizeChanger: false,
    },
  });
  const [totalleads, settotalleads] = useState(0);
  const [exportingData, setExportingData] = useState(false);

  const searchInput = useRef(null);

  useEffect(() => {
    fetchTodayAnalytics(defaultRange[0], defaultRange[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData]);

  const fetchTodayAnalytics = async (startDate, endDate, page = 1) => {
    const [start_date, end_date] = formatDateRange(startDate, endDate);

    setFetchingData(true);

    try {
      const response = await axiosInstance.post(
        "/whatconverts/fetchAnalytics",
        {
          page,
          profile_id: organizationData.apiKeys.whatConverts_profileId,
          start_date,
          end_date,
        }
      );
      settotalleads(response.data.total_leads);
      const transformedData = response.data.leads.map((item) => {
        return {
          date_created: item.date_created,
          "additional_fields.Summary": item.additional_fields.Summary,
          lead_status: item.lead_status,
          "mapped_fields.Booked Campaign": item.additional_fields["Campaign Name"]
            ? item.additional_fields["Campaign Name"]
            : "Not Selected",
          lead_source: item.lead_source,
          lead_medium: item.lead_medium,
          "mapped_fields.Type": item.additional_fields.Type,
          "mapped_fields.Business Unit": item.additional_fields["Business Unit Name"]
            ? item.additional_fields["Business Unit Name"]
            : "Not Selected",
          "mapped_fields.Job Type": item.additional_fields["Job Type Name"]
            ? item.additional_fields["Job Type Name"]
            : "Unbooked",
          "additional_fields.Phone Number":
            item.additional_fields["Phone Number"],
          "additional_fields.Contact Name":
            item.additional_fields["Contact Name"],
        };
      });

      setData(transformedData);
      setTableParams((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          current: page,
          total: response.data.total_leads,
        },
      }));
      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
    }
  };

  const formatDateRange = (startDate, endDate) => {
    const start_date = startDate.format("YYYY-MM-DDTHH:mm:ss[Z]");
    const end_date = endDate.endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
    console.log(start_date, end_date);
    return [start_date, end_date];
  };

  const handleDateChange = async (dates) => {
    if (dates) {
      const [start, end] = dates;

      setDefaultRange([start, end]);
      setFetchingData(true);

      try {
        await fetchTodayAnalytics(start, end, 1);
      } catch (error) {
        console.error("Error fetching analytics:", error);
        setFetchingData(false);
      }
    } else {
      setData([]);
    }
  };

  const handleTableChange = async (pagination) => {
    fetchTodayAnalytics(defaultRange[0], defaultRange[1], pagination.current);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setTableParams((prevState) => ({
      ...prevState,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const exportToExcel = async () => {
    setExportingData(true);
    try {
      const [start_date, end_date] = formatDateRange(
        defaultRange[0],
        defaultRange[1]
      );
      let allData = [];
      if (totalleads > MAX_Leads && totalleads > 0) {
        for (let i = 0; i < Math.ceil(totalleads / MAX_Leads); i++) {
          const response = await axiosInstance.post(
            "/whatconverts/fetchAnalytics",
            {
              page: i + 1,
              profile_id: organizationData.apiKeys.whatConverts_profileId,
              start_date,
              end_date,
              leads_per_page: MAX_Leads,
            }
          );
          allData.push(
            ...response.data.leads.map((item) => {
              return {
                Time: item.date_created,
                "Job ID": item.additional_fields.Summary,
                "Lead Status": item.lead_status,
                "Booked Campaign": item.mapped_fields["Booked Campaign"]
                  ? item.mapped_fields["Booked Campaign"]
                  : "Not Selected",
                "Lead Source": item.lead_source,
                "Lead Medium": item.lead_medium,
                Type: item.mapped_fields.Type,
                "Business Unit": item.mapped_fields["Business Unit"]
                  ? item.mapped_fields["Business Unit"]
                  : "Not Selected",
                "Job Type": item.mapped_fields["Job Type"]
                  ? item.mapped_fields["Job Type"]
                  : "Unbooked",
                "Phone Number": item.additional_fields["Phone Number"],
                "Contact Name": item.additional_fields["Contact Name"],
              };
            })
          );
        }
      } else {
        const response = await axiosInstance.post(
          "/whatconverts/fetchAnalytics",
          {
            page: 1,
            profile_id: organizationData.apiKeys.whatConverts_profileId,
            start_date,
            end_date,
            leads_per_page: totalleads,
          }
        );
        allData = response.data.leads.map((item) => {
          return {
            Time:dayjs.utc(item.date_created).format("ddd D MMMM YYYY, h:mm A"),
            "Job ID": item.additional_fields.Summary,
            "Lead Status": item.lead_status,
            "Booked Campaign": item.mapped_fields["Booked Campaign"]
              ? item.mapped_fields["Booked Campaign"]
              : "Not Selected",
            "Lead Source": item.lead_source,
            "Lead Medium": item.lead_medium,
            Type: item.mapped_fields.Type,
            "Business Unit": item.mapped_fields["Business Unit"]
              ? item.mapped_fields["Business Unit"]
              : "Not Selected",
            "Job Type": item.mapped_fields["Job Type"]
              ? item.mapped_fields["Job Type"]
              : "Unbooked",
            "Phone Number": item.additional_fields["Phone Number"],
            "Contact Name": item.additional_fields["Contact Name"],
          };
        });
      }

      const ws = XLSX.utils.json_to_sheet(allData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Analytics");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataBlob = new Blob([excelBuffer], { type: EXCEL_TYPE });
      const sheetName=`${start_date.split('T')[0]}_${end_date.split("T")[0]}`
      saveAs(
        dataBlob,
        `Analytics_Report_${dayjs().format(sheetName)}.xlsx`
      );
      setExportingData(false);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      setExportingData(false);
    }
  };

  const reloadData = () => {
    fetchTodayAnalytics(
      defaultRange[0],
      defaultRange[1],
      tableParams.pagination.current
    );
  };

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const columns = [
    {
      title: "Time",
      dataIndex: "date_created",
      key: "date_created",
      align: "center",
      style: { whiteSpace: "nowrap" },
      render: (date) => {
        const localTime = dayjs.utc(date)
        return localTime.format("ddd D MMMM YYYY, h:mm A");
      }
    },
    {
      title: "Job ID",
      dataIndex: "additional_fields.Summary",
      key: "Summary",
      align: "center",
      render: (text, record) => {
        return parseInt(text) ? (
          <a
            href={`https://go.servicetitan.com/#/Job/Index/${text}`}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </a>
        ) : (
          <span>Unbooked</span>
        );
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Customer Name",
      dataIndex: "additional_fields.Contact Name",
      align: "center",
      key: "Contact Name",
    },
    {
      title: "Phone",
      dataIndex: "additional_fields.Phone Number",
      align: "center",
      key: "Phone Number",
    },
    {
      title: "Status",
      dataIndex: "lead_status",
      align: "center",
      key: "lead_status",
      filters: [
        { text: "Repeat", value: "Repeat" },
        { text: "Unique", value: "Unique" },
      ],
      onFilter: (value, record) => record.lead_status.includes(value),
    },
    {
      title: "ST Campaign Name",
      dataIndex: "mapped_fields.Booked Campaign",
      key: "Booked Campaign",
      align: "center",
      ...getColumnSearchProps("mapped_fields.Booked Campaign"),
    },
    {
      title: "Source",
      dataIndex: "lead_source",
      key: "lead_source",
      align: "center",
      filters: [
        { text: "google", value: "google" },
        { text: "(direct)", value: "(direct)" },
        { text: "yahoo", value: "yahoo" },
        { text: "bing", value: "bing" },
      ],
      onFilter: (value, record) => record.lead_source.includes(value),
    },
    {
      title: "Medium",
      dataIndex: "lead_medium",
      key: "lead_medium",
      align: "center",
      filters: [
        { text: "organic", value: "organic" },
        { text: "(none)", value: "(none)" },
      ],
      onFilter: (value, record) => record.lead_medium.includes(value),
    },
    {
      title: "Type",
      dataIndex: "mapped_fields.Type",
      key: "Type",
      align: "center",
      filters: [
        { text: "Scheduler Abandoned", value: "Scheduler Abandoned" },
        { text: "Scheduler Started", value: "Scheduler Started" },
        { text: "Scheduled", value: "Scheduled" },
      ],
      onFilter: (value, record) => record["mapped_fields.Type"].includes(value),
    },
    {
      title: "Business Unit",
      dataIndex: "mapped_fields.Business Unit",
      key: "Business Unit",
      align: "center",
      filters: [
        { text: "Not Selected", value: "Not Selected" },
        { text: "Heating", value: "Heating" },
        { text: "Cooling", value: "Cooling" },
        { text: "Electrical", value: "Electrical" },
        { text: "Plumbing", value: "Plumbing" },
        { text: "HVAC", value: "HVAC" },
      ],
      onFilter: (value, record) =>
        record["mapped_fields.Business Unit"].includes(value),
    },
    {
      title: "Job Type",
      dataIndex: "mapped_fields.Job Type",
      key: "Job Type",
      align: "center",
      filters: [
        { text: "Unbooked", value: "Unbooked" },
        { text: "Install", value: "Install" },
        { text: "Maintenance", value: "Maintenance" },
        { text: "Service", value: "Service" },
      ],
      onFilter: (value, record) =>
        record["mapped_fields.Job Type"].includes(value),
    },
  ];

  return (
    <Spin spinning={fetchingData}>
      <Space style={{ marginBottom: 16 }}>
        <RangePicker
          allowClear={false}
          value={defaultRange}
          onChange={handleDateChange}
          format="MMM D, YYYY"
        />
        <Button icon={<ReloadOutlined />} onClick={reloadData} prefix="">
          Reload
        </Button>
        <Button icon={<FileExcelOutlined />} onClick={exportToExcel} loading={exportingData}>
          Export to Excel
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={data}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        rowKey={(record) => record["additional_fields.Summary"]}
      />
    </Spin>
  );
};

export default DetailedAnalysis;
