// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glimpse-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  .glimpse-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms ease, transform 500ms ease;
  }
  .glimpse-exit {
    opacity: 1;
    transform: scale(1);
  }
  .glimpse-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 500ms ease, transform 500ms ease;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UI/CustomCssTransition/customCssTransition.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;EACxB;EACA;IACE,UAAU;IACV,mBAAmB;IACnB,oDAAoD;EACtD;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,sBAAsB;IACtB,oDAAoD;EACtD","sourcesContent":[".glimpse-enter {\n    opacity: 0;\n    transform: scale(0.95);\n  }\n  .glimpse-enter-active {\n    opacity: 1;\n    transform: scale(1);\n    transition: opacity 500ms ease, transform 500ms ease;\n  }\n  .glimpse-exit {\n    opacity: 1;\n    transform: scale(1);\n  }\n  .glimpse-exit-active {\n    opacity: 0;\n    transform: scale(0.95);\n    transition: opacity 500ms ease, transform 500ms ease;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
