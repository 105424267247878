import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Spin,
  Typography,
} from "antd";
import { KeyOutlined} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { updateOrganization } from "../../redux/organizationSlice";
const { Title, Text } = Typography;
const { Item } = Form;

const ThirdPartyInfo = ({ isNewOrg = false }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { organizationData, loading } = useSelector(
    (state) => state?.organization
  );

  const initialValues = useMemo(
    () => ({
      st_tenant_id: "",
      st_client_id: "",
      st_client_secret: "",
      st_app_key: "",
      st_refresh_token: "",
      ghl_api_key: "",
      whatConverts_profileId: "",
      whatConverts_api_key: "",
      whatConverts_secret: "",
      zapier_webhook: "",
    }),
    []
  );

  useEffect(() => {
    if (organizationData) {
      Object.keys(initialValues).map((item) => {
        return form.setFieldValue(`${item}`, organizationData?.apiKeys[item]);
      });
    }
  }, [form, initialValues, organizationData]);

  const handleSubmit = (formValues) => {
    const payload = { apiKeys: formValues };
    dispatch(updateOrganization({ id: organizationData._id, payload }));
  };

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={10}>
        <KeyOutlined style={{ fontSize: 22 }}/>
        <Title level={3} style={{ margin: 0 }}>
          APIs Integration Keys
        </Title>
      </Flex>

      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 20,
        }}
        autoComplete="new-state"
      >
        <Row>
          <Col span={10}>
            <Divider
              orientation="left"
              orientationMargin={0}
              style={{ marginTop: "40px", marginBottom: "10px" }}
            >
              <Text type="secondary" strong underline>
                Service Titan Details
              </Text>
            </Divider>

            <Item name="st_app_key" label="App Key">
              <Input placeholder="App Key" size="large" />
            </Item>

            <Item name="st_client_id" label="Client ID">
              <Input placeholder="Client ID" size="large" />
            </Item>

            <Item name="st_client_secret" label="Client Secret">
              <Input placeholder="Client Secret" size="large" />
            </Item>

            <Item name="st_tenant_id" label="Tenant ID">
              <Input placeholder="Tenant ID" size="large" />
            </Item>
          </Col>

          <Col span={10} offset={1}>
            <Divider
              orientation="left"
              orientationMargin={0}
              style={{ marginTop: "40px", marginBottom: "10px" }}
            >
              <Text
                type="secondary"
                strong
                underline
                style={{ marginBottom: "10px" }}
              >
                WebHook Details
              </Text>
            </Divider>

            <Item name="zapier_webhook" label="Web Hook">
              <Input
                placeholder="Web Hook Url (e.g. Make / Zapier)"
                size="large"
              />
            </Item>

            <Divider
              orientation="left"
              orientationMargin={0}
              style={{ marginTop: "40px", marginBottom: "10px" }}
            >
              <Text
                type="secondary"
                strong
                underline
                style={{ marginBottom: "10px" }}
              >
                WhatConverts Details
              </Text>
            </Divider>

            <Item name="whatConverts_profileId" label="Profile ID">
              <Input placeholder="WhatConverts Profile ID" size="large" />
            </Item>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" size="large">
          Save
        </Button>
      </Form>
    </>
  );
};

export default ThirdPartyInfo;
