import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Button,
  Select,
  Row,
  Col,
  Avatar,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { handleLogout, setOrganizationId } from "../../../redux/authSlice";
import UserDropdown from "./UserDropdown";

const { Header } = Layout;

const DashboardHeader = ({ collapsed, setCollapsed, colorBgContainer }) => {
  const dispatch = useDispatch();

  const {
    isAdmin = false,
    currentOrganizationId,
    currentOrganizationIds,
    user,
  } = useSelector((state) => state?.auth);
  const { organizationsList = [] } = useSelector((state) => state?.admin);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Header
      style={{
        padding: "0 24px",
        background: colorBgContainer,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Row align="middle" style={{ width: "100%" }}>
        {/* Menu Collapse Button */}
        <Col xs={4} lg={1}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "18px",
              width: "48px",
              height: "48px",
              color: "#1890ff",
              transition: "color 0.3s ease",
            }}
          />
        </Col>

        {/* Organization Select Dropdown */}
        <Col xs={16} lg={20} style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          {isAdmin || currentOrganizationId ? (
            <Select
              showSearch
              placeholder="Select an organization"
              optionFilterProp="children"
              value={currentOrganizationId}
              onSelect={(value) => dispatch(setOrganizationId(value))}
              filterOption={filterOption}
              options={(isAdmin
                ? organizationsList
                : organizationsList.filter((item) =>
                    currentOrganizationIds.includes(item._id)
                  )
              )
                ?.map((item) => ({
                  label: item.organization_name,
                  value: item._id,
                }))
                ?.sort((a, b) => a?.label?.localeCompare(b.label))}
              size="large"
              style={{
                width: "100%",
                maxWidth:600,
                borderRadius: 8,
                cursor:"pointer",
                transition: "all 0.3s ease",
              }}
            />
          ) : null}
        </Col>

        {/* User Dropdown */}
        <Col xs={4} lg={3} style={{ display: "flex", justifyContent: "flex-end" }}>
          <UserDropdown user={user} />
        </Col>
      </Row>
    </Header>
  );
};

export { DashboardHeader };
