import { Flex, Input, InputNumber, Switch, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;

const DispatchText = ({
  id,
  dispatch_fee,
  member_dispatch_fee,
  isDefault,
  header,
  body,
  checkboxText,
  onChange,
  dispatchVisibility = true,
}) => {
  const { organizationData } = useSelector((state) => state?.organization);

  const handleDispatchToggle = (e, id) => {
    if (!e) {
      const dispatchObj = {
        dispatch_header:
          header?.trim() === ""
            ? organizationData?.default_dispatch_header
            : header,
        dispatch_body:
          body?.trim() === "" ? organizationData?.default_dispatch_body : body,
        dispatch_text:
          checkboxText?.trim() === ""
            ? organizationData?.default_dispatch_text
            : checkboxText,
      };
      onChange(
        {
          use_default_dispatch: e,
          dispatch_header: dispatchObj?.dispatch_header,
          dispatch_body: dispatchObj?.dispatch_body,
          dispatch_text: dispatchObj?.dispatch_text,
        },
        id
      );
    } else {
      onChange({ use_default_dispatch: e }, id);
    }
  };

  return (
    <Flex vertical gap={20} style={{ width: "100%" }}>
      {/* Dispatch Fee Section */}
      <Flex style={{ width: "100%" }} align="center" gap={20}>
        <Flex vertical gap={10} style={{ flex: 1 }}>
          <Text type="secondary" strong underline>
            Dispatch Fee
          </Text>
          <InputNumber
            size="middle"
            addonBefore="$"
            min={0}
            value={dispatch_fee}
            onChange={(e) => onChange({ dispatch_fee: e }, id)}
            placeholder="Dispatch Fee"
            style={{ width: "100%" }}
          />
        </Flex>

        {dispatchVisibility && (dispatch_fee > 0 || member_dispatch_fee > 0) && (
          <Flex vertical gap={10} style={{ flex: 1 }}>
            <Text type="secondary" strong underline>
              Use Default Dispatch Text
            </Text>
            <Switch
              checked={isDefault}
              onChange={(e) => handleDispatchToggle(e, id)}
              style={{ width: 60 }}
            />
          </Flex>
        )}
      </Flex>

      {/* Dispatch Header */}
      {(dispatch_fee > 0 || member_dispatch_fee > 0) && (
        <Tooltip
          title="Use {{dispatch_fee}} to dynamically include the dispatch fee"
          placement="bottomLeft"
        >
          <Flex vertical gap={10} style={{ width: "100%" }}>
            <Text type="secondary" strong underline>
              Dispatch Header
            </Text>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Custom Dispatch Heading"
			  maxLength={250}
              showCount
			  allowClear
              disabled={isDefault}
              value={
                !isDefault
                  ? header
                  : organizationData?.default_dispatch_header
              }
              onChange={(e) =>
                onChange({ dispatch_header: e.target.value }, id)
              }
              style={{ width: "100%" }}
            />
          </Flex>
        </Tooltip>
      )}

      {/* Dispatch Body */}
      {(dispatch_fee > 0 || member_dispatch_fee > 0) && (
        <Tooltip
          title="Use {{dispatch_fee}} to dynamically include the dispatch fee"
          placement="bottomLeft"
        >
          <Flex vertical gap={10} style={{ width: "100%" }}>
            <Text type="secondary" strong underline>
              Dispatch Body
            </Text>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 5 }}
              placeholder="Custom Dispatch Text"
              maxLength={250}
              showCount
			  allowClear
              disabled={isDefault}
              value={
                !isDefault
                  ? body
                  : organizationData?.default_dispatch_body
              }
              onChange={(e) => onChange({ dispatch_body: e.target.value }, id)}
              style={{ width: "100%" }}
            />
          </Flex>
        </Tooltip>
      )}

      {/* Dispatch Checkbox Text */}
      {(dispatch_fee > 0 || member_dispatch_fee > 0) && (
        <Tooltip
          title="Customize the text for the dispatch checkbox"
          placement="bottomLeft"
        >
          <Flex vertical gap={10} style={{ width: "100%" }}>
            <Text type="secondary" strong underline>
              Dispatch Checkbox Text
            </Text>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Custom Checkbox Text"
              maxLength={25}
              showCount
			  allowClear
              disabled={isDefault}
              value={
                !isDefault
                  ? checkboxText
                  : organizationData?.default_dispatch_text
              }
              onChange={(e) =>
                onChange({ dispatch_text: e.target.value }, id)
              }
              style={{ width: "100%" }}
            />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default DispatchText;
