// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .date-picker-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    width: max-content;
  }
  
  .date-picker-dropdown.adjust-right {
    right: 0;
    left: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Analytics/Overview.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,aAAa;IACb,iBAAiB;IACjB,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,QAAQ;IACR,UAAU;EACZ","sourcesContent":[".date-picker-container {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n  }\n  \n  .date-picker-dropdown {\n    position: absolute;\n    top: 100%;\n    right: 0;\n    z-index: 1000;\n    background: white;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n    padding: 10px;\n    overflow: hidden;\n    width: max-content;\n  }\n  \n  .date-picker-dropdown.adjust-right {\n    right: 0;\n    left: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
