const Plumbing = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6875 31.0312C22.6875 32.663 21.3357 33.985 19.6932 33.9362C18.1125 33.8892 16.875 32.5508 16.875 30.9694V25.4062C16.875 24.6296 16.2454 24 15.4688 24H4.21875C3.44212 24 2.8125 24.6296 2.8125 25.4062V30.8845C2.8125 40.213 10.4442 47.9954 19.7727 48C29.1332 48.0046 36.75 40.3907 36.75 31.0312V25.4062C36.75 24.6296 36.1204 24 35.3438 24H24.0938C23.3171 24 22.6875 24.6296 22.6875 25.4062V31.0312Z"
      fill="#45F6FF"
    ></path>
    <path
      d="M35.3438 24H24.0938C23.3171 24 22.6875 24.6296 22.6875 25.4062V31.0312C22.6875 32.6336 21.3836 33.9364 19.7812 33.9366V47.9999C29.1379 47.9999 36.75 40.3879 36.75 31.0312V25.4062C36.75 24.6296 36.1204 24 35.3438 24Z"
      fill="#2EBEEF"
    ></path>
    <path
      d="M2.8125 4.21875H16.875V25.4062H2.8125V4.21875Z"
      fill="#EBF5FF"
    ></path>
    <path
      d="M43.7812 2.8125H37.2188C29.1934 2.8125 22.6875 9.31838 22.6875 17.3438V25.4062H36.75V17.3438C36.75 17.0849 36.9599 16.875 37.2188 16.875H43.7812V2.8125Z"
      fill="#BEE6FA"
    ></path>
    <path
      d="M42.375 18.2812V1.40625C42.375 0.629625 43.0046 0 43.7812 0H46.5938C47.3704 0 48 0.629625 48 1.40625V18.2812C48 19.0579 47.3704 19.6875 46.5938 19.6875H43.7812C43.0046 19.6875 42.375 19.0579 42.375 18.2812Z"
      fill="#A0CBF0"
    ></path>
    <path
      d="M18.2812 5.625H1.40625C0.629625 5.625 0 4.99538 0 4.21875V1.40625C0 0.629625 0.629625 0 1.40625 0H18.2812C19.0579 0 19.6875 0.629625 19.6875 1.40625V4.21875C19.6875 4.99538 19.0579 5.625 18.2812 5.625Z"
      fill="#A0CBF0"
    ></path>
  </svg>
);

const Electrical = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_99_1354)">
      <path
        d="M2.92634 26.4828C1.31017 26.4828 0 25.1726 0 23.5564C0.000103448 22.7803 0.308586 22.0361 0.857379 21.4874L21.4874 0.857379C22.0361 0.308586 22.7803 0.000103448 23.5564 0C25.1726 0 26.4828 1.31017 26.4828 2.92634C26.4827 3.70241 26.1742 4.44662 25.6254 4.99531L4.99531 25.6254C4.44662 26.1742 3.70241 26.4827 2.92634 26.4828Z"
        fill="#B3B3B3"
      ></path>
      <path
        d="M5.79321 24.8277L24.8277 5.79321L36.0125 16.978C39.8909 20.8562 39.8911 27.1442 36.0129 31.0226C36.0129 31.0226 36.0127 31.0228 36.0125 31.023L31.023 36.0125C27.1448 39.8909 20.8568 39.8911 16.9785 36.0129C16.9785 36.0129 16.9782 36.0127 16.978 36.0125L5.79321 24.8277Z"
        fill="#E6E6E6"
      ></path>
      <path
        d="M30.6206 36.4137L36.4137 30.6206L39.3814 33.5883C40.0276 34.2347 40.0276 35.2824 39.3814 35.9287L35.9287 39.3814C35.2824 40.0276 34.2347 40.0276 33.5883 39.3814L30.6206 36.4137Z"
        fill="#B3B3B3"
      ></path>
      <path
        d="M36.4138 38.8966L38.8966 36.4138L48 45.5173V48H45.5173L36.4138 38.8966Z"
        fill="#808080"
      ></path>
      <path
        d="M5.7931 16.5517L0.513931 11.2726C0.184759 10.9433 -0.000103405 10.4968 4.33936e-08 10.0312C4.33936e-08 9.06178 0.785897 8.27588 1.75531 8.27588C2.22093 8.27578 2.66741 8.46064 2.99669 8.78981L8.27586 14.069L5.7931 16.5517Z"
        fill="#E6E6E6"
      ></path>
      <path
        d="M16.5517 5.7931L11.2726 0.513931C10.9433 0.184759 10.4968 -0.000103405 10.0312 4.33936e-08C9.06178 4.33936e-08 8.27588 0.785897 8.27588 1.75531C8.27578 2.22093 8.46064 2.66741 8.78981 2.99669L14.069 8.27586L16.5517 5.7931Z"
        fill="#E6E6E6"
      ></path>
      <path
        d="M28.9656 29.7931L26.4828 16.5517L23.1725 19.8621L21.5173 15.7241L17.3794 19.8621L20.6897 28.1379L24.0001 24.8276L28.9656 29.7931Z"
        fill="#FDB62F"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_99_1354">
        <rect width="48" height="48" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

const Hvac = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_99_1352)">
      <path
        d="M13.8939 5.65918C12.7707 5.65918 11.681 6.0593 10.8259 6.78577L10.8276 6.78783C10.3221 7.21411 10 7.8593 10 8.58136C10 9.86498 11.018 10.9055 12.2738 10.9055C13.5274 10.9055 14.5439 9.86864 14.5475 8.58821C15.2896 8.8568 15.8222 9.56648 15.8222 10.4C15.8222 11.4632 14.9572 12.3283 13.894 12.3283H0V15.1408H13.8939C16.5079 15.1408 18.6347 13.014 18.6347 10.4C18.6347 7.78599 16.5079 5.65918 13.8939 5.65918Z"
        fill="#B7E4F8"
      ></path>
      <path
        d="M37.5411 39.3755L36.7677 40.1489L35.9942 39.3755L34.0056 41.3642L34.7791 42.1376L34.0056 42.911L35.9942 44.8997L36.7677 44.1264L37.5411 44.8997L39.5298 42.911L38.7564 42.1376L39.5298 41.3642L37.5411 39.3755Z"
        fill="#93D6F4"
      ></path>
      <path
        d="M47.9942 30.8998L46.0056 28.9111L45.2321 29.6846L44.4587 28.9111L42.47 30.8998L43.2434 31.6733L42.47 32.4467L44.4587 34.4353L45.2321 33.662L46.0056 34.4353L47.9942 32.4467L47.2208 31.6733L47.9942 30.8998Z"
        fill="#93D6F4"
      ></path>
      <path
        d="M9.54111 33.9111L8.76777 34.6846L7.99433 33.9111L6.00562 35.8998L6.77905 36.6733L6.00562 37.4467L7.99433 39.4354L8.76777 38.662L9.54111 39.4354L11.5298 37.4467L10.7564 36.6733L11.5298 35.8998L9.54111 33.9111Z"
        fill="#B7E4F8"
      ></path>
      <path
        d="M24.5298 2.36445L22.5411 0.375732L21.7677 1.14917L20.9942 0.375732L19.0056 2.36445L19.7791 3.13788L19.0056 3.91123L20.9942 5.89994L21.7677 5.1266L22.5411 5.89994L24.5298 3.91123L23.7564 3.13788L24.5298 2.36445Z"
        fill="#B7E4F8"
      ></path>
      <path
        d="M45.1874 15.2441C45.1874 9.36086 40.401 4.57446 34.5177 4.57446C30.018 4.57446 26.3573 8.23521 26.3573 12.7349C26.3573 16.1385 29.1263 18.9076 32.53 18.9076C35.0652 18.9076 37.1278 16.845 37.1278 14.3097C37.1278 13.5438 36.8689 12.8375 36.4347 12.2726C35.9748 13.2772 34.9615 13.9755 33.7843 13.9755C32.1749 13.9755 30.8703 12.6708 30.8703 11.0615C30.8703 9.45442 32.1712 8.1513 33.7774 8.14755V8.14689C37.1757 8.14689 39.9402 10.9116 39.9402 14.3096C39.9402 18.3957 36.616 21.72 32.5299 21.72C29.6844 21.72 27.144 20.3901 25.4966 18.3197H0V19.7259V27.7491V29.0809H31.3504C38.9802 29.081 45.1874 22.8738 45.1874 15.2441Z"
        fill="#DBF1FB"
      ></path>
      <path
        d="M25.3357 30.4603V29.0811H0V29.1391V31.8935V31.9516H20.3248C24.3801 31.9516 27.6794 35.2437 27.6794 39.2901C27.6794 42.3221 25.2172 44.7902 22.1832 44.8108C20.2026 44.7902 18.5974 43.1714 18.5974 41.1834C18.5974 39.7595 19.7541 38.6011 21.1758 38.6011C21.426 38.6011 21.6635 38.6548 21.879 38.7499C21.4638 39.171 21.2067 39.7545 21.2067 40.3995C21.2067 41.683 22.2247 42.7236 23.4805 42.7236C24.7363 42.7236 25.7543 41.6831 25.7543 40.3995C25.7543 40.2756 25.7446 40.154 25.7264 40.0355C25.5619 37.6663 23.5845 35.7886 21.1757 35.7886C18.2031 35.7886 15.7848 38.2086 15.7848 41.1834C15.7848 44.7094 18.6312 47.5814 22.1451 47.6224V47.6244C22.162 47.6244 22.1788 47.6238 22.1956 47.6237C22.2041 47.6237 22.2124 47.6244 22.221 47.6244V47.6234C26.7885 47.5825 30.4918 43.8603 30.4918 39.2902C30.4918 35.5107 28.4122 32.2075 25.3357 30.4603Z"
        fill="#B7E4F8"
      ></path>
      <path
        d="M34.5178 4.57446C30.0181 4.57446 26.3573 8.23521 26.3573 12.7349C26.3573 16.1385 29.1263 18.9076 32.53 18.9076C35.0653 18.9076 37.1279 16.845 37.1279 14.3097C37.1279 13.5438 36.8689 12.8375 36.4347 12.2726C35.9748 13.2772 34.9615 13.9755 33.7844 13.9755C32.175 13.9755 30.8703 12.6708 30.8703 11.0615C30.8703 9.45442 32.1712 8.1513 33.7774 8.14755V8.14689C37.1757 8.14689 39.9403 10.9116 39.9403 14.3096C39.9403 18.3957 36.616 21.72 32.5299 21.72C29.6844 21.72 27.1441 20.3901 25.4966 18.3197H23.9607V29.0809H31.3503C38.9802 29.0809 45.1873 22.8738 45.1873 15.244C45.1874 9.36086 40.401 4.57446 34.5178 4.57446Z"
        fill="#B7E4F8"
      ></path>
      <path
        d="M25.7544 40.3994C25.7544 40.2756 25.7448 40.154 25.7265 40.0355C25.6336 38.6969 24.9618 37.5156 23.9607 36.7407V42.6711C24.9859 42.4459 25.7544 41.5146 25.7544 40.3994Z"
        fill="#B7E4F8"
      ></path>
      <path
        d="M34.5179 1.76196C28.4673 1.76196 23.5449 6.68439 23.5449 12.7349C23.5449 14.8439 24.2756 16.7852 25.4967 18.3198C27.1442 20.3901 29.6846 21.7201 32.53 21.7201C36.6161 21.7201 39.9404 18.3958 39.9404 14.3097C39.9404 10.9116 37.1758 8.14698 33.7775 8.14698V8.14754C32.1714 8.15129 30.8704 9.45442 30.8704 11.0615C30.8704 12.6709 32.1752 13.9755 33.7845 13.9755C34.9616 13.9755 35.9748 13.2773 36.4348 12.2726C36.8689 12.8376 37.128 13.5438 37.128 14.3097C37.128 16.845 35.0654 18.9076 32.5301 18.9076C29.1264 18.9076 26.3574 16.1385 26.3574 12.7349C26.3574 8.2352 30.0182 4.57446 34.5179 4.57446C40.4011 4.57446 45.1875 9.36085 45.1875 15.2441C45.1875 22.8739 38.9804 29.081 31.3505 29.081H23.9805H23.9609V32.915C26.1797 34.18 27.6796 36.5628 27.6796 39.29C27.6796 41.7001 26.123 43.7526 23.9609 44.504V47.4243C27.6931 46.5945 30.4921 43.263 30.4921 39.29C30.4921 36.3763 29.2551 33.7463 27.2791 31.8934H31.3506C40.5313 31.8934 48.0001 24.4246 48.0001 15.244C48 7.81004 41.9519 1.76196 34.5179 1.76196Z"
        fill="#93D6F4"
      ></path>
      <path d="M19.9996 22.499H0V25.3115H19.9996V22.499Z" fill="#B7E4F8"></path>
    </g>
    <defs>
      <clipPath id="clip0_99_1352">
        <rect width="48" height="48" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);


export const TradeIcons = {
  "Heating & Cooling": {
    icon: Hvac,
  },
  Plumbing: {
    icon: Plumbing,
  },
  Electrical: {
    icon: Electrical,
  },
};
