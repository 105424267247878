import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  notification,
  Spin,
  TimePicker,
  Switch,
  Table,
  Typography,
  Tooltip,
  Alert,
} from "antd";
import { CalendarOutlined, InfoCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "../../redux/organizationSlice";
import moment from "moment-timezone";
import SaveButton from "../UI/savebutton";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const { Title } = Typography;

function BlockDates() {
  const [holidays, setHolidays] = useState([]);
  const [selectedRange, setselectedRange] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [blockAllday, setBlockAllday] = useState(true);
  const [blockTimeRange, setBlockTimeRange] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const { loading, organizationData } = useSelector(
    (state) => state?.organization
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setHolidays(organizationData?.holidays ?? []);
  }, [organizationData]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...organizationData,
      holidays,
    };
    dispatch(updateOrganization({ id: organizationData._id, payload: data }));
    setIsDirty(false);
  };

  const onRemoveHoliday = (value) => {
    const list = holidays.filter((e, index) => index !== value);
    setHolidays([...list]);
    setIsDirty(true);
  };

  const onAddHoliday = () => {
    if (holidayList.length === 0) {
      notification.error({
        message: "Error",
        description: "Please select dates",
        duration: 3,
      });
      return;
    }
    if (blockAllday && blockTimeRange) {
      notification.error({
        message: "Error",
        description: "Select Block All day or Time. You can't select both",
        duration: 3,
      });
      return;
    } else if (!blockAllday && !blockTimeRange) {
      notification.error({
        message: "Error",
        description:
          "You have to give either Block all day or Time range for the selected blocked dates",
        duration: 3,
      });
      return;
    }
    if (holidays.length === 0) {
      const tranformedDates = holidayList?.map((date) => ({
        date: date,
        TimeRange: blockAllday ? "None" : blockTimeRange,
        BlockAllday: blockTimeRange ? false : true,
      }));
      setHolidays([...tranformedDates]);
    } else {
      const uniqueDates = holidayList?.filter(
        (item) => !holidays.some((holiday) => holiday.date === item)
      );
      const tranformedDates = uniqueDates.map((date) => ({
        date: date,
        TimeRange: blockAllday ? "None" : blockTimeRange,
        BlockAllday: blockTimeRange ? false : true,
      }));
      setHolidays([...holidays, ...tranformedDates]);
    }
    setSelectedDates([]);
    setselectedRange(null);
    setHolidayList([]);
    setBlockTimeRange(null);
    notification.success({
      message: "Success",
      description: "Date successfully inserted",
    });
    setIsDirty(true);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD MMM, YYYY"),
    },
    {
      title: "Time Range",
      dataIndex: "TimeRange",
      key: "TimeRange",
      render: (text, record) => record.TimeRange,
    },
    {
      title: "Block All Day",
      dataIndex: "BlockAllday",
      key: "BlockAllday",
      render: (BlockAllday) => (BlockAllday ? "Yes" : "No"),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Button type="link" danger onClick={() => onRemoveHoliday(index)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
      <form style={{ width: "100%" }}>
        <Spin spinning={loading} fullscreen />
        <Flex align="center" gap={10} style={{ marginBottom: "20px" }}>
          <CalendarOutlined style={{ fontSize: 20 }} />
          <Title level={4} style={{ color: "#333" }}>
            Block Dates
          </Title>
        </Flex>
        <div className="bg-white shadow-md rounded-lg p-6 mb-6  flex flex-col gap-4 items-start w-full">
          <Flex
            vertical
            justify={"space-between"}
            gap={10}
            style={{ width: "100%" }}
          >
            <label style={{ fontWeight: "bold" }}>Select Dates</label>
            <DatePicker
              placeholder="Select Dates"
              style={{ flex: 1, width: "40%", minHeight: "2.5rem" }}
              format="DD-MM-YYYY"
              multiple
              sort
              value={selectedDates}
              onChange={(e) => {
                const formattedDates = e.map(
                  (date) => `${date?.year}-${date?.monthIndex + 1}-${date?.day}`
                );
                setSelectedDates(e);
                setHolidayList([...formattedDates]);
              }}
              plugins={[<DatePanel />]}
            />
            
            <div className="flex flex-col items-start gap-2">
              <label style={{ fontWeight: "bold" }}>Select Time Range</label>
              <TimePicker.RangePicker
                allowClear
                disabled={blockAllday}
                format="h:mm A"
                onChange={(timeRanges, timeStrings) => {
                  setBlockTimeRange(`${timeStrings[0]} - ${timeStrings[1]}`);
                  setselectedRange(timeStrings);
                }}
              />
            </div>

            <div className="flex flex-col items-start gap-2">
              <label style={{ fontWeight: "bold" }}>Block all day</label>
              <Switch
                defaultChecked={true}
                onChange={(value) => {
                  setBlockAllday(value);
                  setBlockTimeRange("");
                  setselectedRange([]);
                }}
              />
            </div>

            <Button
              style={{
                maxWidth: "6rem",
              }}
              onClick={onAddHoliday}
            >
              Add Dates
            </Button>
            
        <Alert showIcon icon={<InfoCircleFilled/>} type="info" message="Block Dates: Use this feature to block specific dates or time ranges, preventing them from being selected for a job."/>

          </Flex>
          <Table
            virtual
            scroll={{ y: "min-content" }}
            dataSource={holidays}
            columns={columns}
            pagination={false}
          />
        </div>

        <SaveButton onSubmit={onSubmit} isDirty={isDirty} />
      </form>
  );
}

export default BlockDates;
