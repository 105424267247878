/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Select, Space, Spin, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrganization } from '../../../../redux/organizationSlice';

const { Option } = Select;

export default function AssignJobs() {
    const { organizationData, loading } = useSelector((state) => state.organization);
    const { jobs: jobList = [] } = useSelector((state) => state.jobs);
    const { services } = useSelector((state) => state.trade);

    

    const dispatch = useDispatch();

    const [selectedTrade, setSelectedTrade] = useState(null);
    const [selectedServiceType, setSelectedServiceType] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [assignJob, setAssignJob] = useState([]);

    useEffect(() => {
        setSelectedCategory(null);
        setSelectedServiceType(null);
        setSelectedTrade(null);
        setAssignJob([]);
    }, [organizationData]);

    useEffect(() => {
        const reducedData = reduceBackendArray(jobList);
        setAssignJob(reducedData[organizationData?.navigation_mapping?.find(item => item.trade.id === selectedTrade)?.trade?.name]?.[selectedServiceType]?.filter(job => jobs.includes(job)) 
        || 
        reducedData[organizationData?.navigation_mapping?.find(item => item.trade.id === selectedTrade)?.trade?.name]?.additionalServices?.[selectedServiceType]?.filter(job => jobs.includes(job)) 
        || []);
    }, [selectedTrade, selectedCategory, selectedServiceType, jobList]);

    const handleTradeChange = (value) => {
        setSelectedTrade(value);
        setSelectedServiceType(null);
        setSelectedCategory(null);
    };

    const handleServiceTypeChange = (value) => {
        setSelectedServiceType(value);
        setSelectedCategory(null);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const reduceBackendArray = (backendArray) => {
        const reducedData = {};

        backendArray?.forEach(job => {
            const { trade, serviceType, jobItem } = job;
            const { tradeName } = trade;

            if (!reducedData[tradeName]) {
                reducedData[tradeName] = {};
            }

            if (!reducedData[tradeName][serviceType]) {
                reducedData[tradeName][serviceType] = [];
            }

            reducedData[tradeName][serviceType].push(jobItem);
        });

        return reducedData;
    };

    const assignJobToCategory = () => {
        if (!selectedTrade || !selectedServiceType || !selectedCategory || !assignJob || assignJob.length === 0) {
            notification.error({
                message: "Error",
                description: "Please select all fields to assign the job.",
                duration: 3,
            });
            return;
        }

        const updatedOrganizationData = structuredClone(organizationData)
        const trade = updatedOrganizationData.navigation_mapping.find(item => item.trade.id === selectedTrade);
        
        if (!trade) {
            notification.error({
                message: "Error",
                description: "Trade not found.",
                duration: 3,
            });
            return;
        }

        if(Object.keys(trade)?.includes(selectedServiceType)){

            const category = trade[selectedServiceType].find(category => category._id === selectedCategory);
        
            if (category) {
                category.jobs = assignJob;
                dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData }));
            } else {
                notification.error({
                    message: "Error",
                    description: "Category not found.",
                    duration: 3,
                });
            }
        }
        else{
            const category = trade?.additionalServices[selectedServiceType].find(category => category._id === selectedCategory);
        
            if (category) {
                category.jobs = assignJob;
                dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData }));
            } else {
                notification.error({
                    message: "Error",
                    description: "Category not found.",
                    duration: 3,
                });
            }
        }

    };

    const reducedData = useMemo(() => reduceBackendArray(jobList), [jobList,organizationData]);

    const trades = organizationData?.navigation_mapping?.map(item => ({
        id: item.trade.id,
        name: item.trade.name
    })) ?? [];

    const categories = selectedTrade && selectedServiceType
        ? organizationData?.navigation_mapping?.find(item => item.trade.id === selectedTrade)[selectedServiceType] || organizationData?.navigation_mapping?.find(item => item.trade.id === selectedTrade)?.additionalServices[selectedServiceType]
        : [];

    const jobs = selectedCategory
        ? categories?.find(category => category._id === selectedCategory)?.jobs
        : [];

    return (
        <>
            <Spin spinning={loading} fullscreen />
            <div style={{ padding: '24px' }}>
                <Card
                    title="Assign Jobs to Categories"
                    bordered={false}
                    style={{
                        width: '100%',
                        marginBottom: 24,
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: '10px',
                    }}
                    headStyle={{ textAlign: 'center', color: '#555', fontWeight: 'bold' }}
                >
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Select
                            placeholder="Select Trade"
                            onChange={handleTradeChange}
                            style={{ width: '100%' }}
                            value={selectedTrade}
                        >
                            {trades.map(trade => (
                                <Option key={trade.id} value={trade.id}>{trade.name}</Option>
                            ))}
                        </Select>

                        <Select
                            placeholder="Select Service Type"
                            onChange={handleServiceTypeChange}
                            style={{ width: '100%' }}
                            disabled={!selectedTrade}
                            value={selectedServiceType}
                        >
                            {services?.map(type => (
                                <Option key={type.id} value={type.id}>{type.name.charAt(0).toUpperCase() + type.name.slice(1)}</Option>
                            ))}
                        </Select>

                        <Select
                            placeholder="Select Category"
                            onChange={handleCategoryChange}
                            style={{ width: '100%' }}
                            disabled={!selectedServiceType}
                            value={selectedCategory}
                        >
                            {categories?.map(category => (
                                <Option key={category._id} value={category._id}>{category.category_name}</Option>
                            ))}
                        </Select>

                        <Select
                            placeholder="Select Job to Assign"
                            mode="multiple"
                            onChange={value => setAssignJob(value)}
                            value={assignJob}
                            style={{ width: '100%' }}
                        >
                            {(reducedData[organizationData?.navigation_mapping?.find(item => item.trade.id === selectedTrade)?.trade?.name]?.[selectedServiceType] || []).map(job => (
                                <Option key={job} value={job}>{job}</Option>
                            ))}
                        </Select>

                        <Button
                            onClick={assignJobToCategory}
                            style={{ width: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                            disabled={!assignJob || !selectedCategory}
                        >
                            Assign Job to Category
                        </Button>
                    </Space>
                </Card>
            </div>
        </>
    );
}
