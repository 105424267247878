import React from "react";
import { Tabs } from "antd";

import { FollowUpLeadsTabs } from "../../utils/constants";

const FollowUpLeadsHome = () => {
	return (
		<Tabs
		    destroyInactiveTabPane
			items={FollowUpLeadsTabs.map((item) => {
				return {
					key: item.name,
					label: (
						<span>
							{item.icon} {item.name}
						</span>
					),
					children: item.component,
				};
			})}
		/>
	);
};

export default FollowUpLeadsHome;
