import React from "react";
import { Button, Col, Flex, Form, Image, Input, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const navigate = useNavigate()

	const {authLoading } = useSelector((state) => state?.auth);

	const onFinish = (values) => {
		dispatch(login({ email: values.email, password: values.password }));
	};

	return (
		<Row style={{ height: "100%" }}>
			 {authLoading ? <Spin spinning={authLoading} fullscreen /> : null}
			<Col span={12} style={{ background: "#fed123", height: "100%" }}>
				<Flex justify="center" align="center" style={{ height: "100%" }}>
					<Image
						src="https://autobot.ai/img/autologo.svg"
						height={100}
						preview={false}
					/>
				</Flex>
			</Col>

			<Col
				span={12}
				style={{
					backgroundImage: `url(https://autobot.ai/img/Whats-the-buzz-bg.png)`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundColor: "#fafcff",
				}}
			>
				<Flex
					justify="center"
					align="center"
					vertical={true}
					style={{ height: "100%" }}
					gap={30}
				>
					<Image
						src="https://autobot.ai/img/Logo_bot_icon_new.svg"
						height={80}
						preview={false}
					/>
					<Form
						name="basic"
						style={{
							width: 300,
						}}
						form={form}
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						autoComplete="on"
						layout="vertical"
					>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message: "Please input a valid Email!",
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							    style={{marginBottom:"0.2rem"}}
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
							]}
						>
							<Input.Password />
						</Form.Item>
							<div style={{marginBottom:"2rem"}}>
								<p onClick={() => navigate('ForgotPassword')} style={{ color: 'gray', cursor: "pointer" }}>Forgot Password?</p>
								</div>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Flex>
			</Col>
		</Row>
	);
};
export default Login;


//helloo