import Title from "antd/es/typography/Title";
import { Col, Row } from "antd";

import RenameJobs from "./CapacityOperations";
import ChangeOrder from "./ChangeCapacityOrder";
import ActiveTradesServices from "./CapacityOperations/activateTradeServices";
import AssignJobs from "./AssignJobs";


export default function NavigationsFlow() {
  return (
    <>
      <Title
        level={3}
        style={{ textAlign: "start", marginBottom: "24px", color: "#333" }}
      >
        Navigation Flows
      </Title>
      <Row justify="center" style={{ padding: "20px" }}>
        <Col xs={24} md={20} lg={18}>
          {/* <MoveJobs /> */}
          <Row gutter={[16, 16]} justify="space-between" align="top">
  <Col xs={24} lg={12}>
    <RenameJobs />
  </Col>
  <Col xs={24} lg={12}>
    <ActiveTradesServices />
  </Col>
  <Col xs={24} lg={12}>
    <AssignJobs />
  </Col>
  <Col xs={24} lg={12}>
    <ChangeOrder />
  </Col>
</Row>
          {/* <RenamingTrades /> */}
        </Col>
      </Row>
    </>
  );
}
