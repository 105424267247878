import React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";

const SaveButton = ({ onSubmit, isDirty }) => {
  const sidebarWidth = 200;
  const { loading } = useSelector((state) => state.organization);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: `${sidebarWidth}px`,
        width: `calc(100% - ${sidebarWidth}px)`,
        backgroundColor: "#fff",
        padding: "8px 0",
        boxShadow: "0px -8px 5.7px 0px #00000014",
        display: "flex",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
      </style>
      <Button
        type="primary"
        danger={isDirty}
        onClick={onSubmit}
        style={{
          fontSize: "12px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: isDirty && !loading ? "#337AB7" : "#C0C0C0",
          border: isDirty && !loading ? "solid 1px #FF0004" : "none",
          color: "#fff",
          height: "32px",
          width: "180px",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "14.52px",
          cursor: isDirty ? "pointer" : "default",
        }}
        disabled={loading || !isDirty}
      >
        Save Changes/Entry{" "}
        <img
          src={require("../../assets/Images/save-icon.png")}
          height={12}
          width={12}
          alt="save"
          style={{
            marginLeft: "8px",
            verticalAlign: "middle",
            display: "inline-block",
          }}
        />
      </Button>
    </div>
  );
};

export default SaveButton;
