import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, Menu, Flex, Image } from "antd";
import { AuthenticatedRoutes } from "../../../utils/constants";
const { Sider } = Layout;
const { Item, ItemGroup } = Menu;

const SideNavbar = ({ collapsed }) => {
	const navigate = useNavigate();
	const { isAdmin, currentOrganizationId } = useSelector(
		(state) => state?.auth
	);

	return (
		<Sider trigger={null} collapsible collapsed={collapsed}>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={["1"]}
				onClick={(e) => navigate(e.key)}
				style={{ height: "100%", padding: "25px 0px" }}
			>
				<Flex align="center" justify="center" style={{ width: "100%" }}>
					{/* <CodeSandboxOutlined
						style={{ fontSize: collapsed ? "50px" : "80px" }}
					/> */}
					<Image
						src="https://autobot.ai/img/Logo_bot_icon_new.svg"
						preview={false}
					/>
				</Flex>
				<Flex vertical={true} justify="space-between" style={{ height: "90%" }}>
					{currentOrganizationId && (
						<>
						<ItemGroup>
							{AuthenticatedRoutes?.map((item) => {
								if ((!item.isAdminRestricted) || (isAdmin && item.name==='Quick Booking Link'))
									return (
										<Item key={item.routePath} icon={item.icon}>
											{item.name}
										</Item>
									);

								return null;
							})}
						</ItemGroup>
					</>
					)}
					<ItemGroup>
						{AuthenticatedRoutes?.map((item) => {
							if (item.isAdminRestricted && isAdmin && item.name!=='Quick Booking Link' && item.name!=='Follow up leads') {
								return (
									<Item
										key={item.routePath}
										icon={item.icon}
										style={{ bottom: 0 }}
									>
										{item.name}
									</Item>
								);
							}
							return null;
						})}
					</ItemGroup>
				</Flex>
			</Menu>
		</Sider>
	);
};
export default SideNavbar;
