// MapLogoIcon.js
import React from 'react';

const MapLogoIcon = ({ isActive }) => {
  return (
    <img
      src={isActive?require('../../assets/Images/blue-map-logo.png'):require('../../assets/Images/map-logo.png')}
      alt="map-logo"
      height={16}
      width={16}
      style={{
        backgroundColor: "white",
        verticalAlign: "middle",
        display: "inline-block",
        marginRight: "10px",
        marginBottom: "2px",
      }}
    />
  );
};

export default MapLogoIcon;
